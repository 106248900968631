<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';

  interface IPros {
    icon: string;
    name: string;
  }
  const prosItems: IPros[] = [
    {
      icon: 'folder',
      name: 'Большая библиотека популярных фильмов и сериалов',
    },
    {
      icon: 'download',
      name: 'Скачивание на телефон в мобильном приложении',
    },
    {
      icon: 'ad',
      name: 'Без рекламы',
    },
    {
      icon: 'smartphone',
      name: 'До 5 устройств на подписку',
    },
    {
      icon: 'mic',
      name: 'Многоголосая озвучка TVShows',
    },
  ];
</script>

<template>
  <section class="advantages">
    <div class="advantages__banner">
      <NuxtImg
        class="advantages__image"
        src="/images/novelty.webp"
        srcset="/images/novelty@2x.webp 2x"
        format="webp"
        height="493"
        width="984"
        alt="novelty"
      />
      <div class="advantages__overlay"></div>
      <div class="advantages__content">
        <h2 class="advantages__content-header">Смотрите новинки кино и сериалов от лучших студий мира</h2>
        <div class="advantages__image-container">
          <div class="advantages__image-overlay">
            <NuxtImg
              class="advantages__image-mobile"
              src="/images/novelty.webp"
              srcset="/images/novelty@2x.webp 2x"
              alt="novelty"
              format="webp"
              height="372"
              width="637"
            />
          </div>
        </div>
        <!--        <div class="advantages__content-offer">-->
        <!--          <span>Первый месяц со скидкой 50% — 99 ₽/месяц</span>-->
        <!--          <p>дальше — 199 ₽/месяц в подписке FLEXY</p>-->
        <!--        </div>-->
        <div class="advantages__content-buttons">
          <v-button media="normal" :to="{ name: 'subscriptions' }">Оформить подписку</v-button>
          <v-button media="normal" color="gradient" appearance="outline" :to="{ name: 'browse' }"> Каталог </v-button>
        </div>
      </div>
    </div>
    <div class="advantages__pros content">
      <div v-for="(item, index) in prosItems" :key="index" class="advantages__pros-item">
        <NuxtImg :src="`/images/advantages/${item.icon}.svg`" width="56" height="56" alt="" />
        {{ item.name }}
      </div>
    </div>
    // Тестовая палитра кнопок, пока не трогать
    <!--    <div style="display: grid; grid-template-columns: repeat(5, 1fr); gap: 20px">-->
    <!--      &lt;!&ndash; Outline с разными цветами &ndash;&gt;-->
    <!--      <VButton appearance="outline" color="main">Outline Main</VButton>-->
    <!--      <VButton appearance="outline" color="secondary">Outline Secondary</VButton>-->
    <!--      <VButton appearance="outline" color="white">Outline White</VButton>-->
    <!--      <VButton appearance="outline" color="gradient" disabled>Outline White</VButton>-->
    <!--      <VButton appearance="outline" color="gray">Outline Gray</VButton>-->
    <!--      <VButton appearance="outline" color="transparent-08">Outline Transparent</VButton>-->

    <!--      &lt;!&ndash; Text с разными цветами &ndash;&gt;-->
    <!--      <VButton appearance="text" color="main">Text Main</VButton>-->
    <!--      <VButton appearance="text" color="secondary">Text Secondary</VButton>-->
    <!--      <VButton appearance="text" color="white">Text White</VButton>-->
    <!--      <VButton appearance="text" color="gray">Text Gray</VButton>-->
    <!--      <VButton appearance="text" color="transparent-08">Text Transparent</VButton>-->

    <!--      &lt;!&ndash; Fill с разными цветами &ndash;&gt;-->
    <!--      <VButton appearance="fill" color="main">Fill Main</VButton>-->
    <!--      <VButton appearance="fill" color="secondary">Fill Secondary</VButton>-->
    <!--      <VButton appearance="fill" color="white">Fill White</VButton>-->
    <!--      <VButton appearance="fill" color="gray">Fill Gray</VButton>-->
    <!--      <VButton appearance="fill" color="transparent-08">Fill Transparent</VButton>-->

    <!--      &lt;!&ndash; Outline с иконкой без текста &ndash;&gt;-->
    <!--      <VButton appearance="outline" icon="bell" color="main">Main</VButton>-->
    <!--      <VButton appearance="outline" icon="bell" color="secondary">secondary</VButton>-->
    <!--      <VButton appearance="outline" icon="bell" color="white" />-->
    <!--      <VButton appearance="outline" icon="bell" color="gray" />-->
    <!--      <VButton appearance="outline" icon="bell" color="transparent-08" />-->

    <!--      &lt;!&ndash; Text с иконкой без текста &ndash;&gt;-->
    <!--      <VButton appearance="text" icon="bell" color="main" />-->
    <!--      <VButton appearance="text" icon="bell" color="secondary" />-->
    <!--      <VButton appearance="text" icon="bell" color="white" />-->
    <!--      <VButton appearance="text" icon="bell" color="gray" />-->
    <!--      <VButton appearance="text" icon="bell" color="transparent-08" />-->

    <!--      &lt;!&ndash; Fill с иконкой без текста &ndash;&gt;-->
    <!--      <VButton appearance="fill" icon="bell" color="main" />-->
    <!--      <VButton appearance="fill" icon="bell" color="secondary" />-->
    <!--      <VButton appearance="fill" icon="bell" color="white" />-->
    <!--      <VButton appearance="fill" icon="bell" color="gray" />-->
    <!--      <VButton appearance="fill" icon="bell" color="transparent-08" />-->

    <!--      &lt;!&ndash; Outline с размерами &ndash;&gt;-->
    <!--      <VButton appearance="outline" size="large">Outline Large</VButton>-->
    <!--      <VButton appearance="outline" size="normal">Outline Normal</VButton>-->
    <!--      <VButton appearance="outline" size="small">Outline Small</VButton>-->

    <!--      &lt;!&ndash; Text с размерами &ndash;&gt;-->
    <!--      <VButton appearance="text" size="large">Text Large</VButton>-->
    <!--      <VButton appearance="text" size="normal">Text Normal</VButton>-->
    <!--      <VButton appearance="text" size="small">Text Small</VButton>-->

    <!--      &lt;!&ndash; Fill с размерами &ndash;&gt;-->
    <!--      <VButton appearance="fill" size="large">Fill Large</VButton>-->
    <!--      <VButton appearance="fill" size="normal">Fill Normal</VButton>-->
    <!--      <VButton appearance="fill" size="small">Fill Small</VButton>-->

    <!--      &lt;!&ndash; Outline с закругленными углами &ndash;&gt;-->
    <!--      <VButton appearance="outline" rounded>Outline Rounded</VButton>-->

    <!--      &lt;!&ndash; Text с закругленными углами &ndash;&gt;-->
    <!--      <VButton appearance="text" rounded>Text Rounded</VButton>-->

    <!--      &lt;!&ndash; Fill с закругленными углами &ndash;&gt;-->
    <!--      <VButton appearance="fill" rounded>Fill Rounded</VButton>-->

    <!--      &lt;!&ndash; Outline с состоянием загрузки &ndash;&gt;-->
    <!--      <VButton appearance="outline" loading>Loading Outline</VButton>-->

    <!--      &lt;!&ndash; Text с состоянием загрузки &ndash;&gt;-->
    <!--      <VButton appearance="text" loading>Loading Text</VButton>-->

    <!--      &lt;!&ndash; Fill с состоянием загрузки &ndash;&gt;-->
    <!--      <VButton appearance="fill" loading>Loading Fill</VButton>-->

    <!--      &lt;!&ndash; Outline с отключенным состоянием &ndash;&gt;-->
    <!--      <VButton appearance="outline" disabled>Disabled Outline</VButton>-->

    <!--      &lt;!&ndash; Text с отключенным состоянием &ndash;&gt;-->
    <!--      <VButton appearance="text" disabled>Disabled Text</VButton>-->

    <!--      &lt;!&ndash; Fill с отключенным состоянием &ndash;&gt;-->
    <!--      <VButton appearance="fill" disabled>Disabled Fill</VButton>-->

    <!--      &lt;!&ndash; Комбинированные примеры &ndash;&gt;-->
    <!--      <VButton appearance="outline" icon="bell" color="main" size="large" rounded loading />-->
    <!--      <VButton appearance="text" icon="bell" color="secondary" size="normal" disabled />-->
    <!--      <VButton appearance="fill" icon="bell" color="white" size="small" rounded />-->
    <!--    </div>-->
  </section>
</template>

<style lang="scss" scoped>
  .advantages {
    padding: 56px 0;
    display: grid;
    gap: 32px;
    &__banner {
      position: relative;
      display: grid;
      align-items: center;
      padding: 38px 0;
    }
    &__overlay {
      position: absolute;
      inset: 0;
      background: linear-gradient(90deg, #0b0b0b 0%, rgba(11, 11, 11, 0) 50%);
    }
    &__image {
      height: auto;
      max-width: 100%;
      content-visibility: auto;
      justify-self: flex-end;
    }
    &__image-container {
      display: none;
    }
    &__content {
      position: absolute;
      display: grid;
      gap: 32px;
      max-width: 566px;
    }
    &__content-header {
      color: $main_white;
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
    }
    &__content-offer {
      display: grid;
      gap: 8px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      span {
        color: $main_green_light;
      }
      p {
        color: $main_white;
      }
    }
    &__content-buttons {
      display: flex;
      gap: 16px;
      a {
        width: fit-content;
      }
    }
    &__pros {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 24px;
    }
    &__pros-item {
      display: flex;
      gap: 24px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: $main-white;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.04);
      padding: 24px;
      grid-row: 2;
      grid-column: auto / span 2;
      align-items: center;

      &:nth-child(1) {
        grid-row: 1;
        grid-column: auto / span 3;
      }
      &:nth-child(2) {
        grid-row: 1;
        grid-column: auto / span 3;
      }
    }
    @media (max-width: $tablet) {
      &__content-header {
        font-size: 36px;
        line-height: 40px;
      }
      &__pros-item {
        font-size: 14px;
        line-height: 20px;
        padding: 16px;
        img {
          width: 32px;
        }
      }
      &__overlay {
        background: linear-gradient(90deg, #0b0b0b 0%, rgba(11, 11, 11, 0) 100%);
      }
    }
    @media (max-width: $retina) {
      gap: 24px;
      padding: 24px 0;
      &__banner {
        padding: 0;
      }
      &__image {
        display: none;
      }
      &__content {
        position: relative;
        max-width: none;
        gap: 16px;
      }
      &__overlay {
        display: none;
      }
      &__image-container {
        display: flex;
        height: 372px;
        justify-content: center;
        align-items: center;
        img {
          height: 372px;
          object-fit: cover;
        }
      }
      &__image-overlay {
        position: absolute;
        left: -1rem;
        right: -1rem;
        height: 372px;
      }
      &__content-header {
        font-size: 28px;
        line-height: 36px;
      }
      &__content-offer {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &__content-buttons {
        flex-direction: column;
        a {
          width: 100%;
        }
      }
      &__pros {
        grid-template-columns: 1fr;
        gap: 16px;
      }
      &__pros-item {
        grid-row: auto;
        grid-column: auto;
        &:nth-child(1) {
          grid-row: auto;
          grid-column: auto;
        }
        &:nth-child(2) {
          grid-row: auto;
          grid-column: auto;
        }
      }
    }
  }
</style>
